import React, { useContext } from "react";
import {
  IonButton,
  IonContent,
  IonInput,
  IonPage,
  IonRouterLink,
  IonText,
} from "@ionic/react";

import { useForm } from "react-hook-form";
import Input, { InputProps } from "../components/Input";
import { useHistory } from "react-router";
import { updateAppContext } from "../AppContext";


const Login: React.FC = () => {
  let history = useHistory();

  const { control, handleSubmit } = useForm();

  const formFields: InputProps[] = [
    {
      name: "email",
      component: <IonInput type="email" required />,
      label: "البريد الإلكتروني*",
    },

    {
      name: "password",
      component: <IonInput type="password" clearOnEdit={false} required />,
      label: "الرقم السري*",
    },
  ];
  const user = useContext(updateAppContext)
  
  const loginUser = (data: any) => {
    async function postData(url = "", data = {}) {
      // Default options are marked with *
      console.log(data);
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }

    postData("https://hemamm.herokuapp.com/auth/local", {
      identifier: data.email,
      password: data.password,
    }).then((data) => {
      if (data.statusCode > 300) {
        alert(`${data.message[0].messages[0].message}`);
      } else {
        alert("Logged in sucssefully");
        user({
          authenticated: true,
          UserId: data.user.id,
          UserRole: data.user.role.name,
          JWT: data.jwt,
          user:data.user
        })
        console.log(data); // JSON data parsed by `data.json()` call
        history.push("/Home");
        window.location.reload()
      }
    });
    console.log("creating a new user account with: ", data);
  };

  return (
    <IonPage>
      <IonContent>
        <div className="ion-padding">
          <IonText color="muted">
            <h2>تسجيل دخول</h2>
          </IonText>
          <form
            onSubmit={handleSubmit((data: any) => {
              loginUser(data);
            })}
          >
            {formFields.map((field, index) => (
              <Input {...field} control={control} key={index} />
            ))}
            <IonButton expand="block" type="submit" className="ion-margin-top">
              تسجيل دخول
            </IonButton>
          </form>
        </div>
        <div style={{ textAlign: 'center'}}>
        <IonRouterLink href='./SignUpV'> زيارتك الأولى؟ انقر هنا</IonRouterLink>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
