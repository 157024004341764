import { IonList, IonListHeader, IonText } from "@ionic/react";
import React from "react";
import { AcceptedVolunteer } from "./AcceptedVolunteer";
import { RegVolenteer } from "./RegVolenteer";

interface RegVolenteers {
  id: number;
  First_Name: string;
  Last_Name: string;
  Volunteer_Picture: any;
  register_events: any;
}
interface props {
  RegVolenteers: RegVolenteers[];
  eventId: number;
}

export const RegVolenteerList: React.FC<props> = (props) => {
  let acceptedV = [];

  console.log(props.RegVolenteers);
  for (let i = 0; i < props.RegVolenteers.length; i++) {
    let Accept;
    for (let j = 0; j < props.RegVolenteers[i].register_events.length; j++) {
      if (props.RegVolenteers[i].register_events[j].event === props.eventId) {
        console.log("asdf");
        if (props.RegVolenteers[i].register_events[j].Accept_Registration) {
          Accept = true;
        } else {
          console.log(props.RegVolenteers[i].register_events[j]);
          Accept = false;
        }
      }
    }
    if (Accept) {
      acceptedV.push(props.RegVolenteers[i]);
      props.RegVolenteers.splice(i, 1);
      console.log(Accept);
    } else {
    }
  }

  return (
    <>
      <IonList>
        <IonListHeader>المتطوعين المقبولين</IonListHeader>
        <IonText color="primary" className="ion-padding">
          عددهم= {acceptedV.length}
        </IonText>
        {acceptedV.map((c) => (
          <AcceptedVolunteer AcceptedVolunteer={c} eventId={props.eventId} />
        ))}
      </IonList>
      <IonList>
        <IonListHeader>المتطوعين المسجلين</IonListHeader>
        <IonText color="primary" className="ion-padding">
          عددهم= {props.RegVolenteers.length}
        </IonText>
        {props.RegVolenteers.map((c) => (
          <RegVolenteer RegVolenteer={c} eventId={props.eventId} />
        ))}
      </IonList>
    </>
  );
};
