import React, { useState } from "react";
import {
  IonItem,
  IonLabel,
  IonBadge,
  IonAlert,
  IonButton,
  IonRouterLink,
} from "@ionic/react";
import axios from "axios";

interface registerEvent {
  id: number;
  event: number;
  Accept_Registration: boolean;
  Rejection_Reason: string;
  Is_Invited: boolean;
}
interface props {
  registerEvent: registerEvent;
  events: any;
}

const Accept = (register_events: any) => {
  console.log(register_events);
  console.log(register_events.id);
  axios
    .put(`https://hemamm.herokuapp.com/register-events/${register_events.id}`, {
      Accept_Registration: true,
    })
    .then(() => window.location.reload());
};

export const HistoryCard: React.FC<props> = (props) => {
  const [ShowReason, setShowReason] = useState(false);
  const [AcceptInvite, setAcceptInvite] = useState(false);
  // console.log(props.registerEvent);
  // console.log(props.registerEvent.event, props.registerEvent);
  return (
    <IonItem>
      <IonRouterLink href={`eventV/${props.registerEvent.event}`}>
        <IonLabel>
          <h2 style={{ paddingRight: "225px" }}>{props.registerEvent.event}</h2>
        </IonLabel>
      </IonRouterLink>

      {props.registerEvent.Accept_Registration === null ? (
        <IonBadge color="warning">جاري فحص الطلب</IonBadge>
      ) : props.registerEvent.Accept_Registration ? (
        <IonBadge color="success">مقبول</IonBadge>
      ) : props.registerEvent.Is_Invited ? (
        <IonBadge onClick={() => setAcceptInvite(true)} color="success">
          تمت دعوتك !!
        </IonBadge>
      ) : (
        <IonBadge onClick={() => setShowReason(true)} color="danger">
          مرفوض
        </IonBadge>
      )}
      {AcceptInvite ? (
        <IonButton onClick={() => Accept(props.registerEvent)}>
          قبول الدعوة
        </IonButton>
      ) : (
        <b />
      )}

      <IonAlert
        isOpen={ShowReason}
        onDidDismiss={() => setShowReason(false)}
        header={"Sorry :("}
        subHeader={"The reason is:"}
        message={props.registerEvent.Rejection_Reason}
        buttons={["OK"]}
      />
    </IonItem>
  );
};
