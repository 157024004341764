import React from 'react';
 
 
import { IonTitle } from '@ionic/react';
import Layout from '../components/Layout';
 
 

const EventOrg: React.FC = () => {
  return (
<Layout>
    <IonTitle>
        EventOrg
    </IonTitle>
</Layout>

  );
};

export default EventOrg;