import React from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { Event } from "./Event";

interface card {
  id: string;
  Location: string;
  Start_date: string;
  Start_time: string;
  Description: string;
  Event_Name: string;
  Event_Image: any;
  End_Date: string;
  End_Time: string;
  skills: any;
}

interface props {
  cards: card[];
}

export const EventList: React.FC<props> = (props) => {
  return (
    <IonGrid class="ion-grid-columns ion-padding" fixed={true}>
      <IonRow>
        {props.cards.map((c) => (
          <IonCol sizeXs="3" sizeLg=".7" sizeMd="1" sizeSm="1">
            <Event card={c} />
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};
