import React from "react";

import {
  IonBackButton,
  // IonBackButton,
  IonButton,
  IonCol,
  IonGrid,
  // IonButtons,
  IonHeader,
  IonIcon,
  IonLabel,
  IonRow,
  // IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import {
  logInOutline,
  logOutOutline,
  chevronBackOutline,
} from "ionicons/icons";

const Header: React.FC = () => {
  const local: any = window.localStorage.getItem("user");
  const auth: any = JSON.parse(local);
  let history = useHistory();
  const deleteuser = {
    authenticated: false,
    UserId: null,
    UserRole: null,
    JWT: null,
    user: null,
  };
  const loguot = (e: any) => {
    e.preventDefault();
    localStorage.setItem("user", JSON.stringify(deleteuser));
    history.push("/Home");
    window.location.reload();
  };
  return (
    <IonHeader mode="ios">
      <IonToolbar color="primary">
        <IonGrid className="ion-text-center">
          <IonRow>
            <IonCol>
              <IonButton
                onClick={() => {
                  history.goBack();
                }}
              >
                <IonIcon icon={chevronBackOutline}></IonIcon>
              </IonButton>
            </IonCol>
            <IonCol>
              <IonLabel>
                <img src="../assets/icon/icon2.svg" height="50" alt="Logo" />
              </IonLabel>
            </IonCol>
            <IonCol>
              {auth.authenticated ? (
                <div>
                  <IonBackButton />
                  <IonButton
                    className="ion-padding"
                    color="danger"
                    href="./home"
                    onClick={loguot}
                    slot="icon-only"
                  >
                    <IonIcon icon={logOutOutline} />
                  </IonButton>
                </div>
              ) : (
                <div>
                  <IonBackButton />
                  <IonButton
                    mode="ios"
                    color="secondary"
                    href="./Login"
                    slot="icon-only"
                  >
                    <IonIcon icon={logInOutline} />
                  </IonButton>
                </div>
              )}{" "}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
