import React, { useEffect, useState } from "react";

import Layout from "../components/Layout";
import { HistoryList } from "../components/HistoryList";

interface volunteer {
  register_events: registerEvent[];
}

interface registerEvent {
  id: number;
  event: number;
  Accept_Registration: boolean;
  Rejection_Reason: string;
  Is_Invited: boolean;
}
interface eventV {
  id: string;
  Location: string;
  Start_date: string;
  Start_time: string;
  Description: string;
  Event_Name: string;
  Event_Image: any;
  End_Date: string;
  End_Time: string;
  organization: any;
  register_events: any;
  skills: any;
}

const VHistory: React.FC = () => {
  const [volunteer, setVolunteer] = useState<volunteer>();
  // const [events, setevents] = useState({} as eventV);
  const events:eventV={}as eventV
  const local: any = window.localStorage.getItem("user");
  const auth: any = JSON.parse(local);
  useEffect(() => {
    fetch(
      `https://hemamm.herokuapp.com/volunteers/${auth.user.volunteer.id}`
    ).then(async (res) => setVolunteer(await res.json()));
    // .then(() => {
    //   console.log("res");
    //   volunteer?.register_events.map((e) => {
    //     fetch(`https://hemamm.herokuapp.com/events?id_in=${e.event}`).then(
    //       async (res) => {
    //         setevents(await res.json());
    //         console.log("object");
    //       }
    //     );
    //   });
    // });
  }, [auth.user.volunteer.id]);

  let register_events: registerEvent[] = [];
  register_events = volunteer?.register_events;

  return (
    <Layout>
      <HistoryList registerEvents={register_events} events={events} />
    </Layout>
  );
};

export default VHistory;
