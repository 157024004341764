import React, { useEffect, useState } from "react";

import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
import Layout from "../components/Layout";
import { Redirect, RouteComponentProps } from "react-router";
import axios from "axios";
import { RegVolenteerList } from "../components/RegVolenteerList";

interface eventV {
  id: string;
  Location: string;
  Start_date: string;
  Start_time: string;
  Description: string;
  Event_Name: string;
  Event_Image: any;
  End_Date: string;
  End_Time: string;
  organization: any;
  register_events: any;
  skills: any;
}
interface UserDetailPageProps
  extends RouteComponentProps<{
    id: string;
  }> {}

const EventV: React.FC<UserDetailPageProps> = ({ match }) => {
  const [eventInfo, setEventinfo] = useState<eventV>();
  const [Volenteers, setVolenteers] = useState<RegVolenteers[]>();
  useEffect(() => {
    fetch(`https://hemamm.herokuapp.com/volunteers`)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setVolenteers(res);
        fetch(`https://hemamm.herokuapp.com/events/${match.params.id}`)
          .then((res) => res.json())
          .then((res) => {
            console.log(res);

            setEventinfo(res);
          });
      });
  }, [match.params.id]);

  const local: any = window.localStorage.getItem("user");
  const auth: any = JSON.parse(local);
  const register = () => {
    axios.post(`https://hemamm.herokuapp.com/register-events`, {
      event: eventInfo?.id,
      volunteer: `${auth.user.volunteer.id}`,
    });
    window.location.reload();
    console.log({
      event: eventInfo?.id,
      volunteer: `${auth.user.volunteer.id}`,
    });
  };

  const Cancel = () => {
    let id;
    for (let i = 0; i < eventInfo.register_events.length; i++) {
      if (eventInfo.register_events[i].volunteer === auth.user.volunteer.id)
        id = eventInfo.register_events[i].id;
    }
    axios
      .delete(`https://hemamm.herokuapp.com/register-events/${id}`)
      .then(() => window.location.reload());
  };

  let regVolunteerIds: number[] = [0];

  regVolunteerIds = eventInfo?.register_events.map((v: any) => {
    return v.volunteer;
  });
  let skillsNames = eventInfo?.skills.map((s: any) => {
    return s.Skill_Name;
  });

  const role = () => {
    if (auth.UserRole === "Organization ") {
      if (auth.user.organization.id === eventInfo?.organization.id) {
        return "mine";
      }
      return "org";
    }
    if (auth.UserRole === "Volunteer") {
      if (regVolunteerIds?.indexOf(auth.user.volunteer.id) !== -1) {
        return "registered";
      }
      return "Volunteer";
    }
    return "unauth";
  };

  interface RegVolenteers {
    id: number;
    First_Name: string;
    Last_Name: string;
    Volunteer_Picture: any;
    register_events: any;
  }

  console.log(Volenteers);

  let Volenteers1: RegVolenteers[] = [];
  if (Volenteers !== undefined) {
    Volenteers1 = Volenteers;
  }

  let regVolunteers: RegVolenteers[] = [];
  for (let i = 0; i < Volenteers1.length; i++) {
    let isRegisted;
    regVolunteerIds?.map((d) => {
      if (d === Volenteers1[i].id) {
        isRegisted = true;
      } else isRegisted = false;

      if (d === Volenteers1[i].id) isRegisted = true;
      else isRegisted = false;

      if (isRegisted) {
        regVolunteers.push(Volenteers1[i]);
        Volenteers1.splice(i, 1);
      }
    });
  }

  console.log(Volenteers1);
  // let history = useHistory();

  // const deleteEvent =()=>{
  //   axios
  //   .delete(`https://hemamm.herokuapp.com/events/${match.params.id}`)
  //   history.push("/Home");
  //   window.location.reload();
  // }
  let eventId = eventInfo?.id;

  const [toInvite, settoInvite] = useState<Boolean>(false);
  return (
    <Layout>
      <div className="ion-text-center">
        <img
          src={eventInfo?.Event_Image.url}
          alt={`${eventInfo?.Event_Name}`}
        />
      </div>
      <IonCard className="ion-text-center">
        <div className="ion-padding">
          <h2 style={{ margin: "auto" }}>
            <b>{eventInfo?.Event_Name}</b>
          </h2>
        </div>
        <IonCardContent className="ion-padding">
          <IonText>{eventInfo?.Description}</IonText>
          <br />
          <div style={{ margin: "auto" }}>
            {skillsNames?.map((s: any) => (
              <IonBadge style={{ margin: ".12rem", padding: "2.6%" }}>
                {s}
              </IonBadge>
            ))}
          </div>
          <IonGrid style={{ margin: "0 auto" }}>
            <IonCol>
              {/* <IonRow>
                <IonLabel>
                  <b>Time:</b> {eventInfo?.Start_time} <b> to </b>{" "}
                  {eventInfo?.End_Time}
                </IonLabel>
              </IonRow> */}
              <IonRow>
                <IonLabel>
                  {" "}
                  <b>التاريخ:</b> {eventInfo?.Start_date} <b> إلى </b>{" "}
                  {eventInfo?.End_Date}
                </IonLabel>
              </IonRow>
              <IonRow>
                <IonLabel>
                  <b>الموقع:</b> {eventInfo?.Location}
                </IonLabel>
              </IonRow>
              <IonRow>
                <IonLabel>
                  <b>الجهة التطوعية:</b>{" "}
                  {eventInfo?.organization.Organization_Name}
                </IonLabel>
              </IonRow>
            </IonCol>
          </IonGrid>
        </IonCardContent>
      </IonCard>
      {role() === "mine" ? (
        <>
          {regVolunteers === [] ? (
            <b />
          ) : (
            <RegVolenteerList
              RegVolenteers={regVolunteers}
              eventId={parseInt(eventInfo?.id)}
            />
          )}
          <IonButton
            expand="block"
            onClick={() => settoInvite(true)}
            className="ion-margin-top"
          >
            {toInvite ? (
              <Redirect
                push
                to={{
                  pathname: "/Invite",
                  state: { Volenteers1, eventId },
                }}
              />
            ) : null}
            ادع متطوعين آخرين
          </IonButton>
        </>
      ) : null}
      {role() === "Volunteer" ? (
        <IonButton expand="block" onClick={register} className="ion-margin-top">
          سجل 
        </IonButton>
      ) : null}
      {role() === "registered" ? (
        <IonButton
          expand="block"
          onClick={Cancel}
          className="ion-margin-top"
          color="danger"
        >
          إلغاء التسجيل
        </IonButton>
      ) : null}
      {role() === "unauth" ? (
        <IonButton expand="block" href="./Login" className="ion-margin-top">
          سجل
        </IonButton>
      ) : null}
      {/* {role() === "mine" ? (
        <IonButton color="danger" expand="block" onClick={deleteEvent} className="ion-margin-top">
          Delete Event !
        </IonButton>
      ) : null} */}
    </Layout>
  );
};

export default EventV;
