import { IonList, IonListHeader } from "@ionic/react";
import React from "react";
import { UnRegVolenteer } from "./UnRegVolenteer";

interface UnRegVolenteers {
  id: number;
  First_Name: string;
  Last_Name: string;
  Volunteer_Picture: any;
  register_events: any;
}
interface props {
  UnRegVolenteers: UnRegVolenteers[];
  eventId: number;
}

export const UnRegVolenteerList: React.FC<props> = (props) => {
  let list = props.UnRegVolenteers;

  // for (let i = 0; i < list.length; i++) {
  //   list[i].register_events.map((e) => {
  //     console.log(e);

  //     if (e.event === props.eventId) {
  //       console.log(e+"ygujftyj");
  //       if (e.Is_Invited) {
  //         list.splice(i, 1);
  //         console.log("object");
  //       }
  //     }
  //   });
  // }
  console.log();
  return (
    <IonList>
      <IonListHeader>متطوعين غير مسجلين</IonListHeader>
      {list?.map((c) => (
        <UnRegVolenteer UnRegVolenteer={c} eventId={props.eventId} />
      ))}
    </IonList>
  );
};
