import React, { useEffect, useState } from 'react';
import { IonAvatar, IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonText, IonTextarea } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { RouteComponentProps, useHistory } from 'react-router';
import Input, { InputProps } from '../components/Input';
import { createOutline } from 'ionicons/icons';
import axios from 'axios'

interface organization {
  Organization_Name: string,
  Address: string,
  Description: string,
  Organization_Status: boolean,
  Mobile_Number: number,
  Organization_Picture: string
}

interface UserDetailPageProps
  extends RouteComponentProps<{
    id: string;
  }> { }
let orgInfo
let imageId = 88
const ProfileOrg: React.FC<UserDetailPageProps> = ({ match }) => {
  let hide = false;
  let zero
  if (match.params.id === '0')
    zero = true
  const local: any = window.localStorage.getItem("user");
  const auth: any = JSON.parse(local);
  // const deleteuser = {
  //   authenticated: false,
  //   UserId: null,
  //   UserRole: null,
  //   JWT: null,
  //   user: null,
  // };
  
  if (auth.UserId == null || auth.UserRole === "Volunteer") hide = true;

  let [editible, setEditible] = useState<boolean>(false)
  const [showLoading, setShowLoading] = useState(false);
  const [finished, setFinished] = useState<boolean>(false);
  let history = useHistory()

  useEffect(() => {
    fetch(`https://hemamm.herokuapp.com/organizations/${match.params.id}`)
      .then((res) => res.json())
      .then((res) => {
        console.log("eee" + JSON.stringify(res));
        orgInfo={
        id: res.id,
        Organization_Name: res.Organization_Name,
        Address: res.Address,
        Description: res.Description,
        Organization_Status: res.Organization_Status,
        Organization_Picture: res.Organization_Picture.url,
        Mobile_Number: res.Mobile_Number,
        }
        setFinished(true)
        console.log("ppp" + orgInfo);
      });
  }, [finished, match.params.id]);

  const UploadImage = async (e) => {
    setShowLoading(true)
    e.preventDefault();
    console.log(e.target.files[0]);
    const data = new FormData();
    data.append("files", e.target.files[0]);
    axios
      .post("https://hemamm.herokuapp.com/upload", data)
      .then((res) => {
        // setPimage(50)
        // console.log(pImage)
        // console.log(res.data[0].id)
        // setPimage(res.data[0].id)
        // console.log(pImage)\
        console.log(res.data[0].id)
        imageId = res.data[0].id
        console.log(imageId)
      })
      .catch(
        (error) => alert(error.response)
      )
  };

  

  const { control, handleSubmit } = useForm();

  const formFields: InputProps[] = [
    {
      name: "orgName",
      component: <IonInput type="text"required />,
      label: "Orgnization Name",
    },
    {
      name: "contactNumber",
      component: <IonInput type="tel" required />,
      label: "Contact Number",
    },
    {
      name: "address",
      component: <IonInput type="text"required />,
      label: "Address",
    },
    {
      name: "description",
      component: <IonTextarea  required />,
      label: "About the Orgnization",
    },
    {
      name: "image",
      component: (<IonText><br />Choose your Orgnization picture:<br /> <input type='file' onChange={(event) =>
         UploadImage(event)} accept="image/*"  /></IonText>),
      label: "",
    },
  ];


  const loginUser = (data: any) => {
    async function postData(url = '', data = {}) {
      // Default options are marked with *
      console.log(data);
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }
    if (zero) {
      console.log(imageId)
      postData('https://hemamm.herokuapp.com/organizations', {
        'Organization_Name': data.orgName,
        "Address": data.address,
        "Description": data.description,
        'user': auth.UserId,
        "Mobile_Number": data.contactNumber,
        "Organization_Picture": imageId
      })
        .then(data => {
          if (data.statusCode > 300) {
            alert(`${data.message[0].messages[0].message}`)
          }
          else {
            alert('Sent sucssefully')
            console.log(data); // JSON data parsed by `data.json()` call
            history.push('/Home');
            window.location.reload()
          }
        });
    } else {
      console.log(imageId)
      axios.put(`https://hemamm.herokuapp.com/organizations/${auth.user.organization.id}`, {
        'Organization_Name': data.orgName,
        "Address": data.address,
        "Description": data.description,
        "Mobile_Number": data.contactNumber,
        "Organization_Picture": imageId
      })
        .then(data => {
          if (data.status > 300) {
            alert(`${data.statusText}`)
          }
          else {
            alert('Saved sucssefully')
            console.log(data); // JSON data parsed by `data.json()` call
            history.push('/Home');
            window.location.reload()
          }
        });
    }
    console.log("saving the account with: ", data);
  };

  return (
    <IonPage>
      {editible? (
      <IonContent>
        <div className="ion-padding">
          <IonLoading
            cssClass='my-custom-class'
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={'Please wait...'}
            duration={12000}
          />
          <IonText color="muted">
            <h2>Editing Page</h2>
          </IonText>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <IonAvatar>
              <img width="100%" height="auto" src={orgInfo?.Organization_Picture} alt="OrgImage" />
            </IonAvatar>
            <IonButton expand="block" onClick={() =>
               { setEditible(!editible) }} hidden={hide} className="ion-margin-top" slot='icon'>
              <IonIcon icon={createOutline} />
            </IonButton>
          </div>
          <IonText color="muted">
            <h2>اسم المستخدم</h2>
          </IonText>
          <form onSubmit={handleSubmit((data: any) => { loginUser(data) })}>
            {formFields.map((field, index) => (
              <Input {...field} control={control} key={index} />
            ))}
            <IonButton expand="block" type="submit" className="ion-margin-top">
              طلب تغيير 
            </IonButton>
            <br />
          </form>
          <div style={{ textAlign: 'center' }}>
            <IonText>ملاحظة: كل المعلومات سيتم مراجعتها من قبل فريقنا</IonText>
          </div>
        </div>
      </IonContent>)
      :(
          <IonContent>
        <div className="ion-padding">
          <IonText color="muted">
            <h2>{orgInfo?.Organization_Name} صفحة</h2>
          </IonText>
          {/* <IonText color="muted">
            <h2>Orgnization status: {orgInfo?.Organization_Status ? 'Approved' : 'Pending'}</h2>
          </IonText> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IonAvatar>
              <img
                width="100%"
                height="auto"
                src={orgInfo?.Organization_Picture}
                alt="VolunteerImage"
              />
            </IonAvatar>
            <IonButton
              expand="block"
              onClick={() => {
                setEditible(!editible);
              }}
              className="ion-margin-top"
              slot="icon"
              hidden={hide}
            >
              <IonIcon icon={createOutline} />
            </IonButton>
          </div>
          <div style={{borderRadius: 5 + 'px', backgroundColor: '#219dde', padding:'8px', margin: '8px'}}>
          <IonText color='light'>
            <p>{orgInfo?.Description}</p>
          </IonText>
          </div>
          <IonItem>
            <IonLabel>اسم الجهة التطوعية</IonLabel>
            <IonText>{orgInfo?.Organization_Name}</IonText>
          </IonItem>
          <IonItem>
            <IonLabel>العنوان</IonLabel>
            <IonText>{orgInfo?.Address}</IonText>
          </IonItem>
          <IonItem>
            <IonLabel>رقم التواصل</IonLabel>
            <IonText>{orgInfo?.Mobile_Number}</IonText>
          </IonItem>
          <div style={{ textAlign: "center" }}>
          </div>
        </div>
      </IonContent>
      )
      } 
    </IonPage>

  );
};

// id: res.id,
//         Organization_Name: res.Organization_Name,
//         Address: res.Address,
//         Profile_Description: res.Profile_Description,
//         Organization_Status: res.Organization_Status,
//         Organization_Picture: res.Organization_Picture.url,
//         Mobile_Number: res.Mobile_Number,
//         username: res.user.username

export default ProfileOrg;