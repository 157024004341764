import React, { useEffect, useState } from "react";

import { IonText } from "@ionic/react";

import Layout from "../components/Layout";

const Home: React.FC = () => {
  const [numberOfEvents, setnumberOfEvents] = useState<number>();

  useEffect(() => {
    fetch(`https://hemamm.herokuapp.com/events/count`)
      .then((res) => res.json())
      .then((res) => {
        setnumberOfEvents(res);
      });
  }, []);

  const [numberOfVolunteer, setnumberOfVolunteer] = useState<number>();

  useEffect(() => {
    fetch(`https://hemamm.herokuapp.com/Volunteers/count`)
      .then((res) => res.json())
      .then((res) => {
        setnumberOfVolunteer(res);
      });
  }, []);

  const [numberOfOrgnaizations, setnumberOfOrgnaizations] = useState<number>();

  useEffect(() => {
    fetch(`https://hemamm.herokuapp.com/organizations/count`)
      .then((res) => res.json())
      .then((res) => {
        setnumberOfOrgnaizations(res);
      });
  }, []);
  return (
    <Layout>
      <IonText color="primary">
        <h1 style={{ textAlign: "center", padding: "4px" }}>
          منصة همم للأعمال التطوعية
        </h1>
      </IonText>

      <IonText color="primary">
        <h2 style={{ textAlign: "center", padding: "4px" }}>
          أكثر من {numberOfEvents} فرصة تطوعية
        </h2>
      </IonText>

      <IonText color="primary">
        <h2 style={{ textAlign: "center", padding: "4px" }}>
          أكثر من {numberOfVolunteer} متطوع
        </h2>
      </IonText>
      <IonText color="primary">
        <h2 style={{ textAlign: "center", padding: "4px" }}>
          أكثر من {numberOfOrgnaizations} جهة تطوعية
        </h2>
      </IonText>
    </Layout>
  );
};
export default Home;
