import React from "react";
import {
  IonTitle,
  IonCard,
  IonCardContent,
  IonButton,
  IonText,
  IonCardHeader,
  IonBadge,
} from "@ionic/react";

interface card {
  id: string;
  Location: string;
  Start_date: string;
  Start_time: string;
  Description: string;
  Event_Name: string;
  Event_Image: any;
  End_Date: string;
  End_Time: string;
  skills: any;
}
interface props {
  card: card;
}

export const Event: React.FC<props> = (props) => {
  return (
    <IonCard
      routerLink={"/eventV/" + props.card.id}
      button={true}
      href={"eventV/" + props.card.id}
      mode="md"
    >
      <img
        width="100%"
        height="auto"
        src={props.card.Event_Image.url}
        alt={props.card.Event_Name}
      />
      <IonCardHeader class="ion-text-center">
        <IonTitle>{props.card.Event_Name}</IonTitle>
        {/* <IonCardSubtitle>{props.card.orgnaization} </IonCardSubtitle> */}
      </IonCardHeader>
      <IonCardContent class="ion-text-center">
        <IonText>{props.card.Description}</IonText>
      </IonCardContent>
      <IonCardContent class="ion-text-center">
        <div className="ion-margin">
          {props.card.skills?.map((s: any) => (
            <IonBadge style={{padding:"2.2%"}}>{s.Skill_Name}</IonBadge>
          ))}
        </div>
        <IonButton href={"event/" + props.card.id} color="secondary">
          عرض
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};
