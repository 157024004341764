import React, { useEffect, useState } from "react";

import { IonChip, IonSearchbar, IonSlide, IonSlides } from "@ionic/react";
import Layout from "../components/Layout";
import Fuse from "fuse.js";
import { UnRegVolenteerList } from "../components/UnRegVolunteerList";

interface UnRegVolenteers {
  id: number;
  First_Name: string;
  Last_Name: string;
  Volunteer_Picture: any;
}
interface props {
  RegVolenteers: UnRegVolenteers[];
  location: any;
}
interface skills {
  id: number;
  Skill_Name: string;
}

const Invite: React.FC<props> = (props) => {
  const [skills, setSkills] = useState<skills[]>();

  useEffect(() => {
    fetch(`https://hemamm.herokuapp.com/skills`)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);

        setSkills(res);
      });
  }, []);

  let skillsNames = skills?.map((s: any) => {
    return s.Skill_Name;
  });
  console.log(props);
  // let list = props.RegVolenteers;
  let list = props.location.state?.Volenteers1;
  let list1 = [
    {
      id: 0,
      First_Name: " string;",
      Last_Name: "string;",
      Volunteer_Picture: { url: "http://placekitten.com/g/200/300" },
    },
  ];
  if (list !== undefined) {
    list1 = list;
  }

  const fuse = new Fuse(list1, {
    keys: ["First_Name", "Last_Name", "skills.Skill_Name"],
    includeScore: true,
  });

  let [searchText, setSearchText] = useState("");

  const results = fuse.search(searchText);
  const characterResults = searchText
    ? results.map((Volunteer) => Volunteer.item)
    : list;

  const slideOptions = {
    slidesPerView: 2.75,
  };

  return (
    <Layout>
      <IonSearchbar
        mode="ios"
        value={searchText}
        onIonChange={(e) => setSearchText(e.detail.value!)}
        placeholder="Enter name of a Volunteer"
        animated
      ></IonSearchbar>
      <IonSlides options={slideOptions}>
        {skillsNames?.map((s: any) => (
          <IonSlide>
            <IonChip outline color="success" onClick={() => setSearchText(s)}>
              {s}
            </IonChip>
          </IonSlide>
        ))}
      </IonSlides>
      <UnRegVolenteerList
        UnRegVolenteers={characterResults}
        eventId={props.location.state?.eventId}
      />
    </Layout>
  );
};

export default Invite;
