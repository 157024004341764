import React, { useEffect, useState } from "react";

import { IonChip, IonSearchbar, IonSlide, IonSlides } from "@ionic/react";
import Layout from "../components/Layout";
import { EventList } from "../components/EventList";
import Fuse from "fuse.js";

interface card {
  id: string;
  Location: string;
  Start_date: string;
  Start_time: string;
  Description: string;
  Event_Name: string;
  Event_Image: any;
  End_Date: string;
  End_Time: string;
  skills: any;
}

interface skills {
  id: number;
  Skill_Name: string;
}

const Events: React.FC = () => {
  // let cardsArr :card[]=[];
  const [cardsArr, setEventinfo] = useState<card[]>();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetch(`https://hemamm.herokuapp.com/events`)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setEventinfo(res);
      });
  }, []);

  const [skills, setSkills] = useState<skills[]>();

  useEffect(() => {
    fetch(`https://hemamm.herokuapp.com/skills`)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);

        setSkills(res);
      });
  }, []);

  let cardsArr1: card[] = [];
  if (cardsArr !== undefined) {
    cardsArr1 = cardsArr;
  }

  let skillsNames = skills?.map((s: any) => {
    return s.Skill_Name;
  });

  const fuse = new Fuse(cardsArr1, {
    keys: ["Event_Name", "orgnaization", "Description", "skills.Skill_Name","Location"],
    includeScore: true,
  });

  const results = fuse.search(searchText);
  const characterResults = searchText
    ? results.map((card) => card.item)
    : cardsArr1;

  const slideOptions = {
    slidesPerView: 2.75,
  };

  return (
    <Layout>
      <IonSearchbar
        mode="ios"
        value={searchText}
        onIonChange={(e) => setSearchText(e.detail.value!)}
        placeholder="ابحث عن فرصة تطوعية"
        animated
      ></IonSearchbar>
      <IonSlides options={slideOptions}>
        {skillsNames?.map((s: any) => (
          <IonSlide>
            <IonChip outline color="success" onClick={() => setSearchText(s)}>
              {s}
            </IonChip>
          </IonSlide>
        ))}
      </IonSlides>
      <EventList cards={characterResults} />
    </Layout>
  );
};

export default Events;
