import { IonList, IonListHeader, IonText } from "@ionic/react";
import React from "react";
import { HistoryCard } from "./HistoryCard";

interface registerEvent {
  id: number;
  event: number;
  Accept_Registration: boolean;
  Rejection_Reason: string;
  Is_Invited: boolean;
}
interface props {
  registerEvents: registerEvent[];
  events: any;
}

export const HistoryList: React.FC<props> = (props) => {
  // let List :registerEvent[]=[{ id:0,
  //   event: 0,
  //   Accept_Registration: true,
  //   Rejection_Reason: "string"}];
  //  List = props.registerEvents ;
  //  const isEmpty = List.length===0

  return (
    <IonList>
      <IonListHeader>سجلي</IonListHeader>
      {false ? (
        <IonText color="primary">Register now !!</IonText>
      ) : (
        props.registerEvents?.map((c) => (
          <HistoryCard registerEvent={c} events={props.events} />
        ))
      )}
    </IonList>
  );
};
