/* eslint-disable array-callback-return */
import React, { useState } from "react";
import {
  IonButton,
  IonItem,
  IonAvatar,
  IonLabel,
  IonButtons,
  IonInput,
  IonRouterLink,
} from "@ionic/react";
import axios from "axios";

interface RegVolenteers {
  id: number;
  First_Name: string;
  Last_Name: string;
  Volunteer_Picture: any;
}
interface props {
  RegVolenteer: RegVolenteers;
  eventId: number;
}

const AcceptOrReject = (v: boolean, volunteer: any, id: number) => {
  const register_event_id = volunteer.register_events.map((e) => {
    if (e.event === id) return e;
  });
  axios.put(
    `https://hemamm.herokuapp.com/register-events/${register_event_id[0].id}`,
    {
      Accept_Registration: v,
    }
  );
  window.location.reload();
};

const Reject = (v: boolean, id: number, volunteer: any, Text: string) => {
  const register_event_id = volunteer.register_events.map((e) => {
    if (e.event === id) return e.id;
  });

  axios.put(
    `https://hemamm.herokuapp.com/register-events/${register_event_id[0]}`,
    {
      Accept_Registration: v,
      Rejection_Reason: Text,
    }
  );

  window.location.reload();
};

export const RegVolenteer: React.FC<props> = (props) => {
  const [reject, setreject] = useState(false);
  const [Text, setText] = useState<string>();

  return (
    <>
      <IonItem>
        <IonAvatar slot="start">
          <img
            src={props.RegVolenteer.Volunteer_Picture.url}
            alt="VolunteerImage"
          />
        </IonAvatar>
        <IonRouterLink href={`ProfileV/${props.RegVolenteer.id}`}>
          <IonLabel>
            <h2>
              {props.RegVolenteer.First_Name} {props.RegVolenteer.Last_Name}
            </h2>
          </IonLabel>
        </IonRouterLink>

        <IonButtons>
          <IonButton
            onClick={() =>
              AcceptOrReject(true, props.RegVolenteer, props.eventId)
            }
            color="success"
          >
            قبول
          </IonButton>
          <IonButton onClick={() => setreject(true)} color="danger">
            رفض
          </IonButton>
        </IonButtons>
      </IonItem>
      {reject ? (
        <>
          <IonInput
            className={"ion-padding"}
            color="primary"
            onIonChange={(e) => setText(e.detail.value!)}
            type={"text"}
            value={Text}
            placeholder={"سبب الرفض "}
          ></IonInput>{" "}
          <br />{" "}
          <IonButton
            color="danger"
            style={{ display: "flex" }}
            type={"submit"}
            onClick={() => {
              console.log(props.RegVolenteer);
              Reject(false, props.eventId, props.RegVolenteer, Text);
            }}
          >
            رفض
          </IonButton>
        </>
      ) : (
        <b />
      )}
    </>
  );
};
