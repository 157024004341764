import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonDatetime,
  IonInput,
  IonLoading,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import Input, { InputProps } from "../components/Input";
import axios from "axios";

interface eventV {
  id: string;
  Location: string;
  Start_date: string;
  Start_time: string;
  Description: string;
  Event_Name: string;
  Event_Image: any;
  End_Date: string;
  End_Time: string;
  organization: any;
  register_events: any;
  skills: any;
  Available_Seats: number;
}
interface Skill {
  id: string;
  Skill_Name: string;
}
let imageId = 99;
const AddEvent: React.FC = () => {
  // const [eventInfo, setEventinfo] = useState<eventV>();
  // let [status, setStatus]  = useState<boolean>(true)
  const [skills, setSkills] = useState<Skill[]>();
  let history = useHistory();
  const [selectedDate1, setSelectedDate1] = useState<string>();
  const [selectedDate2, setSelectedDate2] = useState<string>();
  const [showLoading, setShowLoading] = useState(false);
  const [select, setSelect] = useState<string>();

  const local: any = window.localStorage.getItem("user");
  const auth: any = JSON.parse(local);
  const { control, handleSubmit } = useForm();

  const SkillCom = () => {
    return (
      <IonSelect
        value={select}
        okText="Select"
        cancelText="Dismiss"
        onIonChange={(e) => setSelect(e.detail.value)}
        multiple
      >
        {skills?.map((skill) => {
          return (
            <IonSelectOption value={skill.id}>
              {skill.Skill_Name}{" "}
            </IonSelectOption>
          );
        })}
      </IonSelect>
    );
  };
  const UploadImage = async (e) => {
    setShowLoading(true);
    e.preventDefault();
    console.log(e.target.files[0]);
    const data = new FormData();
    data.append("files", e.target.files[0]);
    axios
      .post("https://hemamm.herokuapp.com/upload", data)
      .then((res) => {
        // setPimage(50)
        // console.log(pImage)
        // console.log(res.data[0].id)
        // setPimage(res.data[0].id)
        // console.log(pImage)\
        console.log(res.data[0].id);
        imageId = res.data[0].id;
        console.log(imageId);
      })
      .catch((error) => alert(error.response));
  };

  const formFields: InputProps[] = [
    {
      name: "eventTitle",
      component: <IonInput type="text" required />,
      label: "اسم الفرصة",
    },
    {
      name: "startDate",
      component: (
        <IonDatetime
          displayFormat="YYYY/MM/DD"
          value={selectedDate1}
          onIonChange={(e) => setSelectedDate1(e.detail.value!)}
          min="2020"
          aria-required
        ></IonDatetime>
      ),
      label: "تاريخ البداية",
    },
    {
      name: "endDate",
      component: (
        <IonDatetime
          displayFormat="YYYY/MM/DD"
          value={selectedDate2}
          onIonChange={(e) => setSelectedDate2(e.detail.value!)}
          min="2020"
        ></IonDatetime>
      ),
      label: "تاريخ النهاية",
    },
    // {
    //   name: "startTime",
    //   component: <IonDatetime displayFormat="h:mm A" value={selectedDate4} onIonChange={e => setSelectedDate4(e.detail.value!)} ></IonDatetime>,
    //   label: "Event start time",
    // },
    // {
    //   name: "endTime",
    //   component: <IonDatetime displayFormat="h:mm A" value={selectedDate3} onIonChange={e => setSelectedDate3(e.detail.value!)} ></IonDatetime>,
    //   label: "Event end time",
    // },
    {
      name: "skills",
      component: <SkillCom />,
      label: "المهارات المطلوبة للفرصة",
    },
    {
      name: "location",
      component: <IonInput type="text" required />,
      label: "موقع الفرصة",
    },
    {
      name: "description",
      component: <IonTextarea required />,
      label: "وصف الفرصة",
    },
    {
      name: "seats",
      component: <IonInput type="number" />,
      label: "عدد المقاعد المتاحة",
    },
    {
      name: "image",
      component: (
        <IonText>
          <br />
          اختر صورة للفرصة 
          <br />{" "}
          <input
            type="file"
            onChange={(e) => UploadImage(e)}
            accept="image/*"
          />
        </IonText>
      ),
      label: "",
    },
  ];

  const loginUser = (data: any) => {
    async function postData(url = "", data = {}) {
      // Default options are marked with *
      console.log(data);
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }
    postData("https://hemamm.herokuapp.com/events", {
      Event_Name: data.eventTitle,
      Location: data.location,
      Description: data.description,
      Start_date: data.startDate,
      End_Date: data.endDate,
      Start_time: data.startTime,
      End_Time: data.endTime,
      skills: skills,
      Event_Image: imageId,
      organization: auth.user.organization.id,
      Available_Seats: data.seats,
    }).then((data) => {
      if (data.statusCode > 300) {
        alert(`${data.message}`);
      } else {
        alert("تم إضافة الفرصة التطوعية بنجاح");
        console.log(data); // JSON data parsed by `data.json()` call
        history.push("/Home");
        window.location.reload();
      }
    });
    console.log("saving the event with: ", data);
  };

  useEffect(() => {
    fetch(`https://hemamm.herokuapp.com/skills`)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setSkills(res);
      });
  }, []);

  return (
    <IonPage>
      <IonContent>
        <div className="ion-padding">
          <IonLoading
            cssClass="my-custom-class"
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Please wait..."}
            duration={12000}
          />
          <IonText color="muted">
            <h2>إضافة فرصة</h2>
          </IonText>
          <form
            onSubmit={handleSubmit((data: any) => {
              loginUser(data);
            })}
          >
            {formFields.map((field, index) => (
              <Input {...field} control={control} key={index} />
            ))}
            <IonButton expand="block" type="submit" className="ion-margin-top">
              إضافة فرصة
            </IonButton>
            <br />
            <IonText>ملاحظة: كل المعلومات سيتم مراجعتها من قبل فريقنا</IonText>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AddEvent;
