import React, { useState } from "react";
import {
  IonButton,
  IonItem,
  IonAvatar,
  IonLabel,
  IonButtons,
  IonInput,
  IonRouterLink,
} from "@ionic/react";
import axios from "axios";

interface RegVolenteer {
  id: number;
  First_Name: string;
  Last_Name: string;
  Volunteer_Picture: any;
}
interface props {
  AcceptedVolunteer: RegVolenteer;
  eventId: number;
}

const Reject = (v: boolean, id: number, volunteer: any, Text: string) => {
  // eslint-disable-next-line array-callback-return
  const register_event_id = volunteer.register_events.map((e) =>  {
      if (e.event === id)
        return e.id;
    });

  axios.put(
    `https://hemamm.herokuapp.com/register-events/${register_event_id[0]}`,
    {
      Accept_Registration: v,
      Rejection_Reason: Text,
    }
  );
  window.location.reload();
};

export const AcceptedVolunteer: React.FC<props> = (props) => {
  const [reject, setreject] = useState(false);
  const [Text, setText] = useState<string>();

  return (
    <>
      <IonItem>
        <IonAvatar slot="start">
          <img src={props.AcceptedVolunteer.Volunteer_Picture.url} alt="AcceptedVolImage" />
        </IonAvatar>
        <IonRouterLink href={`ProfileV/${props.AcceptedVolunteer.id}`}>
          <IonLabel>
            <h2>
              {props.AcceptedVolunteer.First_Name}{" "}
              {props.AcceptedVolunteer.Last_Name}
            </h2>
          </IonLabel>
        </IonRouterLink>

        <IonButtons>
          <IonButton onClick={() => setreject(true)} color="danger">
            رفض
          </IonButton>
        </IonButtons>
      </IonItem>
      {reject ? (
        <>
          <IonInput
            className={"ion-padding"}
            color="primary"
            onIonChange={(e) => setText(e.detail.value!)}
            type={"text"}
            value={Text}
            placeholder={"سبب الرفض: "}
          ></IonInput>{" "}
          <br />{" "}
          <IonButton
            color="danger"
            style={{ display: "flex" }}
            type={"submit"}
            onClick={() => {
              console.log(props.AcceptedVolunteer);
              Reject(false, props.eventId, props.AcceptedVolunteer, Text);
            }}
          >
            رفض
          </IonButton>
        </>
      ) : (
        <b />
      )}
    </>
  );
};
