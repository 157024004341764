import React from 'react';
import { IonButton } from '@ionic/react';
// import ExploreContainer from '../components/ExploreContainer';
import './Tab1.css';
import Layout from '../components/Layout'

const Tab1: React.FC = () => {
  return (
    <Layout >
<IonButton color="primary">Primary</IonButton>
    </Layout>
  );
};

export default Tab1;
