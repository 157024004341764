import { IonButton, IonItem } from "@ionic/react";
import axios from "axios";
import React, { useState } from "react";
import Layout from "../components/Layout";

function ForgotPass() {
  const [email, setemail] = useState<string>();
  const Reset = (e) => {
    e.preventDefault();
    console.log(email);
    axios
      .post("https://hemamm.herokuapp.com/auth/forgot-password", {
        email: email, // user's email
      })
      .then((response) => {
        console.log("Your user received an email");
      })
      .catch((error) => {
        console.log("An error occurred:", error.response);
      });
  };
  return (
    <Layout>
      <form onSubmit={Reset}>
        <IonItem>
          <input
            type="text"
            placeholder="Inter Milan your email"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          ></input>
        </IonItem>
        <IonButton expand="block" type="submit" className="ion-margin-top">
          Reset
        </IonButton>
      </form>
    </Layout>
  );
}

export default ForgotPass;
