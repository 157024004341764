import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom"; //, Router
import {
  IonApp,
  IonIcon,
  IonLabel,
  // IonIcon,
  // IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  // IonTabBar,
  // IonTabButton,
  // IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  addSharp,
  homeOutline,
  mapOutline,
  personOutline,
  receiptOutline,
  // triangle,
} from "ionicons/icons";
import Tab1 from "./pages/Tab1";
import Tab2 from "./pages/Tab2";
import Tab3 from "./pages/Tab3";
import Profile from "./pages/ProfileV";
import SignUp from "./pages/SignUpV";
import Details from "./pages/Details";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Global CSS */
// import './global.css';
import Home from "./pages/Home";
import AddEvent from "./pages/AddEvent";
import Events from "./pages/Events";
import ProfileOrg from "./pages/ProfileOrg";
import ProfileV from "./pages/ProfileV";
import SignUpOrg from "./pages/SignUpOrg";
import EventV from "./pages/EventV";
import EventOrg from "./pages/EventOrg";
import Login from "./pages/Login";

import { AppProvider } from "./AppContext";
import Invite from "./pages/Invite";
import ForgotPass from "./pages/ForgotPass";
import VHistory from "./pages/VHistory";
import { updateAppContext } from "./AppContext";

const App: React.FC = () => {
  const user = useContext(updateAppContext);

  let auth: any;
  if (localStorage.getItem("user")) {
    let local: any = window.localStorage.getItem("user");
    auth = JSON.parse(local);
  } else {
    user({
      authenticated: false,
      UserId: 0,
      UserRole: "Anonymous",
      JWT: "null",
      user: {},
    });
    auth = user;
  }
  return (
    <IonApp>
      <AppProvider>
        {auth.UserId ? (
          <IonReactRouter>
            <IonTabs>
              <IonRouterOutlet>
                <Route path="/tab1" component={Tab1} exact={true} />
                <Route path="/tab2" component={Tab2} exact={true} />
                <Route path="/tab2/details" component={Details} />
                <Route path="/tab3" component={Tab3} />
                <Route path="/Profile" component={Profile} exact={true} />
                <Route path="/SignUpV" component={SignUp} />
                <Route path="/SignUpOrg" component={SignUpOrg} />
                <Route path="/Home" component={Home} exact={true} />
                <Route path="/AddEvent" component={AddEvent} exact={true} />
                <Route path="/EventV/:id" component={EventV} />
                <Route path="/EventOrg" component={EventOrg} />
                <Route path="/Events" component={Events} />
                <Route path="/Login" component={Login} />
                <Route path="/ProfileOrg/:id" component={ProfileOrg} />
                <Route path="/ProfileV/:id" component={ProfileV} />
                <Route path="/Invite" component={Invite} />
                <Route path="/ForgotPass" component={ForgotPass} />
                <Route path="/VHistory" component={VHistory} />
                <Route
                  path="/"
                  render={() => <Redirect to="/Home" />}
                  exact={true}
                />
              </IonRouterOutlet>
              {auth.UserRole === "Organization " ? (
                <IonTabBar slot="bottom" color="primary">
                  <IonTabButton tab="Home" href="/Home">
                    <IonIcon icon={homeOutline} />
                    <IonLabel>الرئيسة</IonLabel>
                  </IonTabButton>
                  <IonTabButton tab="AddEvent" href="/AddEvent">
                    <IonIcon icon={addSharp} />
                    <IonLabel>إضافة فرصة</IonLabel>
                  </IonTabButton>
                  <IonTabButton tab="Events" href="/Events">
                    <IonIcon icon={mapOutline} />
                    <IonLabel>الفرص</IonLabel>
                  </IonTabButton>
                  {auth.user.organization ? (
                    <IonTabButton
                      tab="ProfileOrg"
                      href={`/ProfileOrg/${auth.user.organization.id}`}
                    >
                      <IonIcon icon={personOutline} />
                      <IonLabel>الملف الشخصي</IonLabel>
                    </IonTabButton>
                  ) : (
                    <IonTabButton tab="ProfileOrg" href={`/ProfileOrg/0`}>
                      <IonIcon icon={personOutline} />
                      <IonLabel>الملف الشخصي</IonLabel>
                    </IonTabButton>
                  )}
                </IonTabBar>
              ) : (
                <IonTabBar slot="bottom" color="primary">
                  <IonTabButton tab="Home" href="/Home">
                    <IonIcon icon={homeOutline} />
                    <IonLabel>الرئيسة</IonLabel>
                  </IonTabButton>
                  <IonTabButton tab="Events" href="/Events">
                    <IonIcon icon={mapOutline} />
                    <IonLabel>الفرص</IonLabel>
                  </IonTabButton>
                  <IonTabButton tab="VHistory" href="/VHistory">
                    <IonIcon icon={receiptOutline} />
                    <IonLabel>سجلي</IonLabel>
                  </IonTabButton>
                  {auth.user.volunteer ? (
                    <IonTabButton
                      tab="ProfileV"
                      href={`/ProfileV/${auth.user.volunteer.id}`}
                    >
                      <IonIcon icon={personOutline} />
                      <IonLabel>الملف الشخصي</IonLabel>
                    </IonTabButton>
                  ) : (
                    <IonTabButton tab="ProfileV" href={`/ProfileV/0`}>
                      <IonIcon icon={personOutline} />
                      <IonLabel>الملف الشخصي</IonLabel>
                    </IonTabButton>
                  )}
                </IonTabBar>
              )}
            </IonTabs>
          </IonReactRouter>
        ) : (
          <IonReactRouter>
            <IonTabs>
              <IonRouterOutlet>
                <Route path="/tab1" component={Tab1} exact={true} />
                <Route path="/tab2" component={Tab2} exact={true} />
                <Route path="/tab2/details" component={Details} />
                <Route path="/tab3" component={Tab3} />
                <Route path="/Profile" component={Profile} exact={true} />
                <Route path="/SignUpV" component={SignUp} />
                <Route path="/SignUpOrg" component={SignUpOrg} />
                <Route path="/Home" component={Home} exact={true} />
                <Route path="/AddEvent" component={AddEvent} exact={true} />
                <Route path="/EventV/:id" component={EventV} />
                <Route path="/EventOrg" component={EventOrg} />
                <Route path="/Events" component={Events} />
                <Route path="/Login" component={Login} />
                <Route path="/ProfileOrg/:id" component={ProfileOrg} />
                <Route path="/ProfileV/:id" component={ProfileV} />
                <Route
                  path="/"
                  render={() => <Redirect to="/Home" />}
                  exact={true}
                />
              </IonRouterOutlet>
              <IonTabBar slot="bottom" color="primary">
                <IonTabButton tab="Home" href="/Home">
                  <IonIcon icon={homeOutline} />
                  <IonLabel>الرئيسة</IonLabel>
                </IonTabButton>
                <IonTabButton tab="Events" href="/Events">
                  <IonIcon icon={mapOutline} />
                  <IonLabel>الفرص</IonLabel>
                </IonTabButton>
              </IonTabBar>
            </IonTabs>
          </IonReactRouter>
        )}
      </AppProvider>
    </IonApp>
  );
};

export default App;
