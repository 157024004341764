import { IonContent, IonPage, IonText, IonInput, IonButton, IonCheckbox, IonItem, IonLabel, IonRouterLink } from "@ionic/react";
import React , { useContext }from "react";
import { useForm } from "react-hook-form";
import Input, { InputProps } from "../components/Input";
import { useHistory } from 'react-router' 
import { updateAppContext } from "../AppContext";

const SignUpV: React.FC = () => {

  const { control, handleSubmit } = useForm();
  let logged=false
  let history = useHistory()


  const formFields: InputProps[] = [
    {
      name: "email",
      component: <IonInput type="email" required />,
      label: "البريد الإلكتروني*",
    },
    {
      name: "username",
      label: "اسم المستخدم*",
    },
    {
      name: "password",
      component: <IonInput type="password" clearOnEdit={false}  required/>,
      label: "الرقم السري*",
    },
  ];
  const user = useContext(updateAppContext)
  const registerUser = (data: any) => {
    async function postData(url = '', data = {}) {
      // Default options are marked with *
      console.log(data);
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }
    
    postData('https://hemamm.herokuapp.com/auth/local/register', {
    "username":data.username,
    "email":data.email,
    "password":data.password,
    "role": 4})
      .then(data => {
        if(data.statusCode >300){
        alert(`Error: ${data.statusCode}: ${data.message[0].messages[0].message}`)
      }else{
        alert('تم التسجيل بنجاح، يرجى مراجعة البريد الشخصي لتأكيد الحساب')
        user({
          authenticated: true,
          UserId: data.user.id,
          UserRole: data.user.role.name,
          JWT: data.jwt,
          user:data.user
        })
        console.log(data);
        logged = true
      }if(logged)
      history.push('/ProfileV/0'); 
      });
    console.log("creating a new user account with: ", data);
  };

  return (
    <IonPage>
      <IonContent>
        <div className="ion-padding">
          <IonText color="muted">
            <h2>إنشاء حساب متطوع</h2>
          </IonText>
          <form onSubmit={handleSubmit((data: any) => {registerUser(data)})}>
            {formFields.map((field, index) => (
              <Input {...field} control={control} key={index} />
            ))}
            <IonItem>
              <IonLabel>أوافق على الشروط والأحكام</IonLabel>
              <IonCheckbox slot="start" />
            </IonItem>
            
            <IonButton expand="block" type="submit" className="ion-margin-top">
              سجل
              </IonButton>
          </form>
        </div>
        <div style={{ textAlign: "center"}}>
        <IonRouterLink href='./Login'>لديك حساب؟ سجل دخولك من هنا</IonRouterLink>
        <br/>
        <br/>
        <IonRouterLink href='./SignUpOrg'>جهة تطوعية؟ اضغط هنا</IonRouterLink>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SignUpV;