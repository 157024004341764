import React, { useEffect, useState } from "react";
import {
  IonAvatar,
  IonButton,
  IonContent,
  IonDatetime,
  IonInput,
  IonItem,
  IonPage,
  IonRadioGroup,
  IonText,
  IonTextarea,
  IonLabel,
  IonRadio,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonLoading,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import { RouteComponentProps, useHistory } from "react-router";
import Input, { InputProps } from "../components/Input";
import { createOutline } from "ionicons/icons";
import axios from "axios";

//       "DOB": data.date,
//       "Gender": data.gender,
//       "Profile_Description": data.description,
//       "First_Name": data.firstName,
//       "Last_Name": data.lastName,
//       "Mobile_Number": data.mobile,
//       "skills": skills,

interface Skill {
  id: string;
  Skill_Name: string;
}
interface UserDetailPageProps
  extends RouteComponentProps<{
    id: string;
  }> { }
  let vInfo 
  let imageId =66
const ProfileV: React.FC<UserDetailPageProps> = ({ match }) => {
  let hide = false;
  let zero;
  if (match.params.id === "0") zero = true;

  const local: any = window.localStorage.getItem("user");
  const auth: any = JSON.parse(local);
  // const deleteuser = {
  //   authenticated: false,
  //   UserId: null,
  //   UserRole: null,
  //   JWT: null,
  //   user: null,
  // };

  if (auth.UserId === null || auth.UserRole === "Organization ") hide = true;

  const [editible, setEditible] = useState<boolean>(false);
  const [finished, setFinished] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>("2012-12-15");
  const [selected, setSelected] = useState<string>();
  const [select, setSelect] = useState<string>();
  const [skills, setSkills] = useState<Skill[]>();
  const [showLoading, setShowLoading] = useState(false);
  //const [vInfo, setVinfo] = useState<volunteer>();
  let history = useHistory();

  useEffect(() => {
    fetch(`https://hemamm.herokuapp.com/volunteers/${match.params.id}`)
      .then((res) => res.json())
      .then((res) => {
        console.log("eee" + JSON.stringify(res));
        vInfo={
        id: res.id,
        First_Name: res.First_Name,
        Last_Name: res.Last_Name,
        Mobile_Number: res.Mobile_Number,
        Profile_Description: res.Profile_Description,
        DOB: res.DOB,
        Volunteer_Picture: res.Volunteer_Picture.url,
        Gender: res.Gender,
        skills: res.skills,
        username: res.user.username
        }
        setFinished(true)
        console.log("ppp" + vInfo);
      });
  }, [finished, match.params.id]);

  const UploadImage = async (e) => {
    setShowLoading(true)
    e.preventDefault();
    console.log(e.target.files[0]);
    const data = new FormData();
    data.append("files", e.target.files[0]);
    axios
      .post("https://hemamm.herokuapp.com/upload", data)
      .then((res) => {
        // setPimage(50)
        // console.log(pImage)
        // console.log(res.data[0].id)
        // setPimage(res.data[0].id)
        // console.log(pImage)\
        console.log(res.data[0].id)
        imageId = res.data[0].id
        console.log(imageId)
      })
      .catch(
        (error) => alert(error.response)
      )
  };

  const SkillCom = () => {
    return (
      <IonSelect
        value={select}
        okText="Select"
        cancelText="Dismiss"
        onIonChange={(e) => setSelect(e.detail.value)}
        multiple
      >
        {skills?.map((skill) => {
          return (
            <IonSelectOption value={skill.id}>
              {skill.Skill_Name}
            </IonSelectOption>
          );
        })}
      </IonSelect>
    );
  };
  
  
  const { control, handleSubmit } = useForm();

  const formFields: InputProps[] = [
    {
      name: "firstName",
      component: <IonInput type="text"  required />,
      label: "الاسم الأول",
    },
    {
      name: "lastName",
      component: <IonInput type="text"  required />,
      label: "اسم العائلة",
    },
    {
      name: "mobile",
      component: <IonInput type="tel" required />,
      label: "رقم الجوال",
    },
    {
      name: "description",
      component: <IonTextarea  required />,
      label: "عن نفسك",
    },
    {
      name: "date",
      component: (
        <IonDatetime
          displayFormat="YYYY-MM-DD"
          value={selectedDate}
          onIonChange={(e) => setSelectedDate(e.detail.value!)}
        ></IonDatetime>
      ),
      label: "تاريخ الميلاد",
    },

    {
      name: "image",
      component: (
        <IonText >
          <br />
          صورة الملف الشخصي
          <br />{" "}
          <input
            onChange={(event) => UploadImage(event)}
            type="file"
            accept="image/*"
          />
        </IonText>
      ),
      label: "",
    },
    {
      name: "gender",
      component: (
        <IonRadioGroup
          value={selected}
          onIonChange={(e) => setSelected(e.detail.value)}
        >
          <IonItem>
            <IonLabel>ذكر</IonLabel>
            <IonRadio slot="start" value="male" mode="md" />
          </IonItem>
          <IonItem>
            <IonLabel>أنثى</IonLabel>
            <IonRadio
              slot="start"
              value="female"
              mode="md"
            />
          </IonItem>
        </IonRadioGroup>
      ),
      label: "الجنس",
    },
    {
      name: "skills",
      component: <SkillCom />,
      label: "المهارات",
    },
  ];

  const loginUser = async (data: any) => {
    async function postData(url = "", data = {}) {
      // Default options are marked with *
      console.log("print1 :" + data);
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }
    if (zero) {
      console.log(imageId)
      postData(`https://hemamm.herokuapp.com/volunteers`, {
        DOB: data.date,
        Gender: data.gender,
        user: auth.UserId,
        Profile_Description: data.description,
        First_Name: data.firstName,
        Last_Name: data.lastName,
        Mobile_Number: data.mobile,
        skills: skills,
        Volunteer_Picture: imageId,
      }).then((data) => {
        if (data.statusCode > 300) {
          alert(`${data.message[0].messages[0].message}`);
        } else {
          alert("تم الحفظ بنجاح");
          console.log(data); // JSON data parsed by `data.json()` call
          history.push("/Home");
          window.location.reload();
        }
      });
      console.log("saving the account with: ", data);
    } else {
      console.log(imageId)
      axios
        .put(
          `https://hemamm.herokuapp.com/volunteers/${auth.user.volunteer.id}`,
          {
            "DOB": data.date,
            "Gender": data.gender,
            "Profile_Description": data.description,
            "First_Name": data.firstName,
            "Last_Name": data.lastName,
            "Mobile_Number": data.mobile,
            "skills": skills,
            "Volunteer_Picture": imageId,
          }
        )
        .then((data) => {
          if (data.status > 300) {
            alert(`${data.statusText}`);
          } else {
            alert("تم الحفظ بنجاح");
            console.log(data); // JSON data parsed by `data.json()` call
            history.push("/Home");
            window.location.reload();
          }
        });
    }
  };
  useEffect(() => {
    fetch(`https://hemamm.herokuapp.com/skills`)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setSkills(res);
      });
  }, []);

  return (
    <IonPage>
      {editible? (
      <IonContent>
        <div className="ion-padding">
        <IonLoading
            cssClass='my-custom-class'
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={'يرجى الإنتظار'}
            duration={12000}
          />
          <IonText color="muted">
            <h2>Editing Profile</h2>
          </IonText>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IonAvatar>
              <img
                width="100%"
                height="auto"
                src={vInfo?.Volunteer_Picture}
                alt="VolunteerImage"
              />
            </IonAvatar>
            <IonButton
              expand="block"
              onClick={() => {
                setEditible(!editible);
              }}
              className="ion-margin-top"
              slot="icon"
              hidden={hide}
            >
              <IonIcon icon={createOutline} />
            </IonButton>
          </div>
          <IonText color="muted">
            <h2>{vInfo?.username}</h2>
          </IonText>
          <form
            onSubmit={handleSubmit((data: any) => {
              loginUser(data);
            })}
          >
            {formFields.map((field, index) => (
              <Input {...field} control={control} key={index} />
            ))}
            {/* <input onChange={(event) =>{setPimage(event.target.files[0])}} type='file' accept="image/*" disabled={editible} />     */}
            <IonButton
              expand="block"
              type="submit"
              className="ion-margin-top"
            >
              حفظ
            </IonButton>
            {/* <IonInput type="text" disabled={editible} value={vInfo?.Mobile_Number}/> */}
          </form>
          <div style={{ textAlign: "center" }}>
          </div>
        </div>
      </IonContent>
      ):
      (
        <IonContent>
        <div className="ion-padding">
          <IonText color="muted">
            <h2>الشخصي {vInfo?.username} ملف </h2>
          </IonText>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IonAvatar>
              <img
                width="100%"
                height="auto"
                src={vInfo?.Volunteer_Picture}
                alt="VolunteerImage"
              />
            </IonAvatar>
            <IonButton
              expand="block"
              onClick={() => {
                setEditible(!editible);
              }}
              className="ion-margin-top"
              slot="icon"
              hidden={hide}
            >
              <IonIcon icon={createOutline} />
            </IonButton>
          </div>
          <div style={{borderRadius: 5 + 'px', backgroundColor: '#219dde', padding:'8px', margin: '8px'}}>
          <IonText color='light'>
            <p>{vInfo?.Profile_Description}</p>
          </IonText>
          </div>
          <IonItem>
            <IonLabel>اسم المتطوع</IonLabel>
            <IonText>{vInfo?.First_Name+' '}{vInfo?.Last_Name}</IonText>
          </IonItem>
          <IonItem>
            <IonLabel>الجنس</IonLabel>
            <IonText>{vInfo?.Gender}</IonText>
          </IonItem>
          <IonItem>
            <IonLabel>تاريخ الميلاد</IonLabel>
            <IonText>{vInfo?.DOB}</IonText>
          </IonItem>
          <IonItem>
            <IonLabel>رقم التواصل</IonLabel>
            <IonText>{vInfo?.Mobile_Number}</IonText>
          </IonItem>
          <div style={{margin: '8px'}}>
          <IonLabel>المهارات</IonLabel>
          </div>
          <IonItem className='padding-top'>
            <IonText>{vInfo?.skills?.map((skill) => 
              skill.Skill_Name +' | ' )}
              </IonText>
          </IonItem>
          <div style={{ textAlign: "center" }}>
          </div>
        </div>
      </IonContent>
      ) 
      }
    </IonPage>
  );
};

// id: res.id,
//         First_Name: res.First_Name,
//         Last_Name: res.Last_Name,
//         Mobile_Number: res.Mobile_Number,
//         Profile_Description: res.Profile_Description,
//         DOB: res.DOB,
//         Volunteer_Picture: res.Volunteer_Picture.url,
//         Gender: res.Gender,
//         skills: res.skills,
//         username: res.user.username
export default ProfileV;
