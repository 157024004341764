import React from "react";
import { useState, createContext } from "react";

interface ContextProps {
  authenticated: boolean;
  UserId: number;
  UserRole: string;
  JWT: string;
  user: any;
}

export const AppContext = createContext<ContextProps>({
  authenticated: false,
  UserId: 0,
  UserRole: "Anonymous",
  JWT: "string",
  user: {},
});

function login1(user: ContextProps) {}

export const updateAppContext = createContext<any>(login1);

export const AppProvider: React.FC = ({ children }) => {
  const [authUser, setAuthUser] = useState<ContextProps>({
    authenticated: false,
    UserId: 0,
    UserRole: "Anonymous",
    JWT: "null",
    user: {},
  });

  const login2 = (user: React.SetStateAction<ContextProps>) => {
    setAuthUser(user);
    window.localStorage.setItem("user", JSON.stringify(user));
  };

  return (
    <AppContext.Provider value={authUser}>
      <updateAppContext.Provider value={login2}>
        {children}
        {window.localStorage.getItem("user")
          ? null
          : window.localStorage.setItem("user", JSON.stringify(authUser))}
      </updateAppContext.Provider>
    </AppContext.Provider>
  );
};
