import React from "react";
import {
  IonButton,
  IonItem,
  IonAvatar,
  IonLabel,
  IonButtons,
  IonRouterLink,
} from "@ionic/react";
import axios from "axios";

interface UnRegVolenteer {
  id: number;
  First_Name: string;
  Last_Name: string;
  Volunteer_Picture: any;
}
interface props {
  UnRegVolenteer: UnRegVolenteer;
  eventId: number;
}
const isInvite = (volunteer, eventId) => {
  axios.post(`https://hemamm.herokuapp.com/register-events`, {
    event: eventId,
    volunteer: volunteer.id,
    Accept_Registration: false,
    Rejection_Reason: Text,
    Is_Invited: true,
  });
};

export const UnRegVolenteer: React.FC<props> = (props) => {
  return (
    <>
      <IonItem>
        <IonAvatar slot="start">
          <img src={props.UnRegVolenteer.Volunteer_Picture.url} alt="asd"/>
        </IonAvatar>
        <IonRouterLink href={`ProfileV/${props.UnRegVolenteer.id}`}>
          <IonLabel>
            <h2>
              {props.UnRegVolenteer.First_Name} {props.UnRegVolenteer.Last_Name}
            </h2>
          </IonLabel>
        </IonRouterLink>
        <IonButtons>
          <IonButton
            color="success"
            onClick={() => isInvite(props.UnRegVolenteer, props.eventId)}
          >
            ادع
          </IonButton>
        </IonButtons>
      </IonItem>
    </>
  );
};
